<template>
  <v-combobox
      v-model="selected"
      :items="items"
      item-text="name"
      label="Выберите месторождение"
      :rules="rules"
      :disabled="this.tectonic == null"
      @change="itemSelected($event)"
  ></v-combobox>
</template>

<script>
import selectorMixin from "@/components/modal/selector/mixin/selector-mixin";

export default {
  name: "DepositSelector",
  mixins: [selectorMixin],
  props: ['tectonic'],
  computed: {
    items() {
      if (this.tectonic != null)
        return this.tectonic.mineral_deposits
      else
        return []
    }
  },
  watch: {
    tectonic() {
      this.selected = null
      this.itemSelected(this.selected)
    }
  }
}
</script>
